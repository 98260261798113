import {
    ACC_SET_CURRENT,
    SLIDER_NEXT,
    SLIDER_PREV,
    SLIDER_SET_CURRENT,
} from './types';

const initialState = {
    slideCurrent: 0,
    accCurrent: 0
}

export const counterReducer = (state = initialState, action) => {
    console.log('STATE', state)
    console.log('ACTION', action)
    switch (action.type) {

        case SLIDER_NEXT: {
            let pos = state.slideCurrent
            if (pos < 7) {
                pos++
            } else {
                pos = 0
            }
            return {slideCurrent: pos}
        }

        case SLIDER_PREV: {
            let pos = state.slideCurrent
            if (pos > 0) {
                pos--
            } else {
                pos = 7;
            }
            return {slideCurrent: pos}
        }

        case SLIDER_SET_CURRENT:
            return {slideCurrent: action.payload}

        case ACC_SET_CURRENT:
            let id = action.payload
            if (id > 3) {
                id-=4
            }
            return {accCurrent: id}
        default:
            return state
    }
}