// import {getCurrentSlide, setCurrentSlide} from "./circle-slider";


import {setCurrentAcc, setCurrentSlide} from "./redux/actions";

export class Accordion {
    constructor(selectors, {store}) {
        const {root, items} = selectors
        this.root = document.querySelector(root)
        this.items = document.querySelectorAll(items)
        this.store = store
        this.currentItem = this.root.querySelector('.item-1')
    }

    init() {
        this.root.addEventListener('click', (e) => {

            this.writeCurrent(e)
            this.subscribe()
        })
        return this
    }

    subscribe() {
        this.store.subscribe(() => {
            let {slideCurrent} = this.store.getState()
            if (slideCurrent > 3){
                slideCurrent -= 4
            }
            this.currentItem = this.items[+slideCurrent]
            this.choiceActiveItem()

        })

    }


    writeCurrent(e) {
        this.currentItem = e.target.closest('.ws-accordion-item')
        let id = this.currentItem.dataset.id
        this.store.dispatch(setCurrentSlide(+id))
    }


    choiceActiveItem() {
        if (this.currentItem) {
            this.items.forEach(item => {
                item.classList.remove('active')
            })
            this.currentItem.classList.add('active')
        }

    }
}




