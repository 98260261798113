import Player from '@vimeo/player'


document.addEventListener("DOMContentLoaded", () => {

    let videoPlayer = new Player('circlePlayer', {
        id: 433166427,
        width: 1045,
        height: 580,
        loop: true,
        muted: true,
        controls: false,
        autoplay: true
    })
    let currentSlider = 1;

    const mute = () => {
        videoPlayer.setMuted(true)
    }

    const unmute = () => {
        videoPlayer.setMuted(false)
    }

    const domPlayer = document.querySelector('#circlePlayer');

    const updatePlayer = (id, videoPlayer) => {
        videoPlayer.loadVideo(id)
    }


    domPlayer.addEventListener('mouseover', unmute)

    domPlayer.addEventListener('mouseout', mute)

    videoPlayer.on('pause', () => {
        videoPlayer.play()
    })

    let slider = document.querySelector('.circle-outer')
    slider.onclick = (e) => {
        let selectedItem = e.target.closest('.ws-item')
        if (selectedItem) {
            currentSlider = selectedItem.dataset.id
            let id = selectedItem.dataset.video
            updatePlayer(id, videoPlayer)
        }
    }

    videoPlayer.on('loaded', () => {
        domPlayer.classList.add('loaded')
    })
    videoPlayer.on('playing', () => {
        domPlayer.classList.remove('loaded')

    })

})