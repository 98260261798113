import {
    prev,
    next,
    setCurrent, setCurrentSlide,
} from './redux/actions';

export class CircleSlider {
    constructor(selectors, {store}) {
        const {root, items, arrows} = selectors
        this.root = document.querySelector(root)
        this.items = document.querySelectorAll(items) || []
        this.currentItem = this.root.querySelector('.item-1')
        this.nextButton = document.querySelector(`${arrows} .ws-arrow-next`)
        this.prevButton = document.querySelector(`${arrows} .ws-arrow-prev`)
        this.store = store

    }

    init() {
        this.root.addEventListener('click', e => {
            this.writeCurrent(e)
            this.scrollSlider()
            this.choiceActiveItem()
        })
        this.nextButton.addEventListener('click', () => {
            this.goToNext()
        })
        this.prevButton.addEventListener('click', () => {
            this.goToPrev()
        })

        this.subscribe()
        return this
    }

    subscribe() {
        this.store.subscribe(() => {
            let {slideCurrent} = this.store.getState()

            if (typeof slideCurrent !== 'undefined')
                this.scrollToPosition(slideCurrent)
        })
    }

    scrollSlider() {
        if (this.currentItem) {
            const z = 270 - +this.currentItem.style.getPropertyValue('--z').replace('deg', '')
            this.root.style.setProperty('--z', z + 'deg');
        }
    }

    choiceActiveItem() {
        if (this.currentItem) {
            this.items.forEach(item => {
                item.classList.remove('active')
            })
            this.currentItem.classList.add('active')
        }

    }

    writeCurrent(e) {
        this.currentItem = e.target.closest('.ws-item')
        let id = this.currentItem.dataset.id
        this.store.dispatch(setCurrentSlide(+id))
    }

    scrollToPosition(id) {
        this.items[id].click()
    }


    goToNext() {
        this.store.dispatch(next())
    }

    goToPrev() {
        this.store.dispatch(prev())
    }
}
