import {
    ACC_SET_CURRENT,
    SLIDER_NEXT,
    SLIDER_PREV,
    SLIDER_SET_CURRENT,
} from './types';

export const next = () => ({type: SLIDER_NEXT})
export const prev = () => ({type: SLIDER_PREV})
export const setCurrentSlide = id => ({type: SLIDER_SET_CURRENT, payload: id})
export const setCurrentAcc = id => ({type: ACC_SET_CURRENT, payload: id})