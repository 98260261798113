import './assets/home-cirle-slider.css'
import './vimeo-player'


import {CircleSlider} from './circle-slider';
import {Accordion} from './accordeon';
import {store} from './redux';


const accordion = new Accordion({
    root: '.ws-accordion-wrapper',
    items: '.ws-accordion-item'
}, {store})
    .init();

const slider = new CircleSlider({
    root: '.circle-outer',
    items: '.ws-item',
    arrows: '.ws-arrows'
}, {store})
    .init()
